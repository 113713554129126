import React from 'react';
import styled from 'styled-components';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const Center = styled.div`
  text-align: center;
`;

function AboutPage() {
  return (
    <Layout breakCrumbs={[{label: "Liên hệ chúng tôi"}]}>
      <SEO title="Liên hệ chúng tôi" />
      <Center>
        <iframe
          title="lien-he"
          src="https://docs.google.com/forms/d/e/1FAIpQLSd4BfgQDMPCMMkTpXGlubbPuM1JE67W9mD3rDegyY1XXLzKyA/viewform?embedded=true"
          width="640"
          height="840"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
        >
          Đang tải…
        </iframe>
      </Center>
    </Layout>
  );
}

export default AboutPage;
